
import { defineComponent, Ref, ref, onUpdated , onMounted, onBeforeMount, onUnmounted, watch, DefineComponent } from "vue";
import { Modal } from "bootstrap";
import Api from "@/services/Api"
import useAlert from "@/composables/Alert";
import { useLoaderStore } from "@/store/LoaderStore";

export default defineComponent({
  name: "ModalJSONIntegracao",
  components: { },
  props: { 
    codConcessionaria: Number, idAtendimento: Number, cleanModal: Boolean, 
    nomeEmpresa: String, jsonIntegracao: Object, auxModal: Object ,
    clicked: Boolean
  },

  setup(props) {

    const json: Ref<any> = ref({})
    const Jsonfiltered: Ref<any> = ref({})
    const nomeEmpresaDinamico: Ref<any> = ref('');
    const loaderStore = useLoaderStore();
    const { showTimeAlert } = useAlert();


    async function statusIntegracao() {
      loaderStore.open();
      try{
        const res = await Api.post("getLogAtendimentosPorID", {
          idLoja: props.codConcessionaria, 
          idAtendimento: props.idAtendimento,
        });
        json.value = res.data
        if(!json.value.length){
          showTimeAlert('Atendimento não possui histórico de transmissão', "error");
          props.auxModal && props.auxModal.hide()
        }
      }catch(e){
        console.log(e)
        showTimeAlert('Erro ao buscar histórico de transmissão', "error");
      }finally{
        loaderStore.close();
      }
    }

    function clickedJson(id: number) {
      Jsonfiltered.value = json.value.filter((item: any) => item.id === id) 
    }

    const copyContent = () => {
      let textoSelecionado = document.getElementById('texto')?.innerHTML;
      if(textoSelecionado)
      navigator.clipboard.writeText(textoSelecionado);
    };

    function formatDate(date: any) {
      const formattedDate = new Date(date);
      const day = String(formattedDate.getDate()).padStart(2, '0');
      const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
      const year = formattedDate.getFullYear();
      const hours = String(formattedDate.getHours()).padStart(2, '0');
      const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
      const seconds = String(formattedDate.getSeconds()).padStart(2, '0');
      
      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    }

    watch(
      () => props.clicked,  
      () => {
        statusIntegracao()
      }
    )

    watch(
      () => props.cleanModal,
      () => Jsonfiltered.value = {}
    )
    watch(
      () => props.jsonIntegracao,
      () => {json.value= {}, Jsonfiltered.value = {}}
      
    )
    watch(
      () => props.nomeEmpresa,
      () => nomeEmpresaDinamico.value = props.nomeEmpresa
    )

    return { json, copyContent, formatDate, clickedJson, Jsonfiltered, nomeEmpresaDinamico, loaderStore }
  }

})

