
import { defineComponent, Ref, ref } from "vue";
import { Modal } from "bootstrap";
import Button from "@/components/Button.vue"
import Api from "@/services/Api"
import useEmitter from '@/composables/Emmiter';
import { useLoaderStore } from "@/store/LoaderStore";
import useAlert from "@/composables/Alert"
import CurrencyValueMask from '@/services/CurrencyValueMask'



export default defineComponent({
  name: "EditItemModal",

  components: {
    Button,
  },
  props: {
    closeModal: Function,
    getItens: Function,
    // setEmptyItemParam: Function,
    // itemParam: Object,
  },

  setup(props) {
    const getUrlParam = (param: string) => (new URLSearchParams(window.location.search)).get(param);
    const emitter = useEmitter();

    function brMask(i: any) {
      let v = i.replace(/\D/g, '');
      v = (v / 100).toFixed(2) + '';
      v = v.replace(".", ",");
      i = v;
      item.value.valor = i
    }

    // --------------------------------------------------
    const attendanceId: Ref<number | null> = ref(getUrlParam('id') ? Number(getUrlParam('id')) : null);

    const item: Ref<any> = ref({});
    const statusItem: string[] = ['oferecido', 'vendido', 'orcar', 'orcado', 'recusado', 'aprovado'];
    const message: Ref<string | null> = ref(null)
    const triedToSave: Ref<boolean> = ref(false)
    const loaderStore = useLoaderStore()
    const { showTimeAlert } = useAlert()

    const dismiss = () => {
      props.closeModal && props.closeModal();
      props.getItens && props.getItens();

      message.value = null;
      triedToSave.value = false

    }

    const requiredFieldsFilled = (): boolean => {

      const { codItemLoja, nome, qtd, status, valor } = item.value

      message.value = null;
      let value = true;

      if (!codItemLoja || !nome || !qtd || !valor) {
        message.value = 'Preencha os campos obrigatórios!'
        value = false
      }

      return value;

    }

    function handleValue(value){
      return value.replace("R$", "").trim();
    }

    const saveItem = async () => {
      triedToSave.value = true;

      if (!requiredFieldsFilled()) return;
      loaderStore.open()
      try {
        item.value.valor = handleValue(item.value.valor)
        item.value.valorOriginal = handleValue(item.value.valorOriginal)
        item.value.desconto = handleValue(item.value.desconto)
        item.value.valorMo = handleValue(item.value.valorMo)

        const response = await Api.post('saveAttendanceItem', { ...item.value, idOrcamento: attendanceId.value })
        dismiss()
        showTimeAlert('Item editado com sucesso');
      } catch (error) {
        showTimeAlert('Erro ao editar o item', 'error');
        console.log("err", error)
      } finally {
        loaderStore.close()
      }

    }

    const deleteItem = async () => {
      loaderStore.open()
      try {
        const { data } = await Api.delete('deleteAttendanceItem', { id: item.value.id })
        dismiss()
      } catch (error) {
        console.log("error", error)
      } finally {
        loaderStore.close()
      }
    }

    emitter.on("item-data-attendance-details", async params => {
      item.value = params.item

      if (item.value.valorOriginal == null || Number.isNaN(item.value.valorOriginal)) {
        item.value.valorOriginal = 0
      }
    
    });


    // onUpdated(() => {
    //   item.value = props.itemParam
    //   console.log('itremetmme', props.itemParam)
    //   item.value.valorOriginal = (item.value.valorOriginal + parseFloat(item.value.desconto)).toFixed(2)
    //   item.value.desconto = item.value.desconto == null || Number.isNaN(item.value.desconto) ? 0 : (parseFloat(item.value.desconto) / item.value.qtd).toFixed(2)
    // });


    return {
      item,
      statusItem,
      saveItem,
      deleteItem,
      dismiss,
      message,
      triedToSave,
      brMask,
      CurrencyValueMask
    }
  }

})

