
import {
  defineComponent,
  Ref,
  ref,
  onBeforeMount,
} from "vue";
import EditItemModal from "./components/EditItemModal.vue";
import Button from "@/components/Button.vue"
import { Modal } from "bootstrap";
import Api from "@/services/Api";
import store from "@/store";
import ModalJSONIntegracao from "./components/ModalJSONIntegracao.vue";
import useAlert from "@/composables/Alert";
import { useLoaderStore } from "@/store/LoaderStore";
import useEmitter from '@/composables/Emmiter';


interface attendanceType {
  id: number | null;
  placa: string;
  veiculo: string;
  marca: string;
  ano: string;
  km: string;
  fipe: string;
  cliente: string;
  documento: string;
  email: string;
  telefone: string;
  responsavel: string;
  status: string;
  valor: string;
  valorOriginal: number | null;
  valorDesconto: string;
  valorFinal: string;
  statusIntegracao: string;
  created_at: string;
  codConcessionaria?: any;
  observacao: string;
}

interface ItemType {
  id: number | null;
  idOrcamento: number | null;
  codItemLoja: string;
  nome: string;
  valor: string;
  created_at: string;
  updated_at: string
  status: string;
  codPacote: string;
  valorPeca: string;
  valorMo: string;
  categoria: string;
  codAgregados: string;
  campoCheckList: string;
  desconto: string;
  tipoItem: string;
  qtd: number | null;
  valorUnitario: number | null;
  valorOriginal: any;
}

interface getDownloadAtendimento {
  idOrcamento: number;
  idLoja: number;
  idUsuario: number;
}

export default defineComponent({
  name: "AttendanceDetails",

  components: {
    EditItemModal,
    ModalJSONIntegracao,
    Button
  },

  setup() {
    const auxModal: Ref<any> = ref(null);
    const { showTimeAlert } = useAlert();
    const loaderStore = useLoaderStore();
    const emitter = useEmitter();
    // --------------------------------------------------
    const getUrlParam = (param: string) => new URLSearchParams(window.location.search).get(param);
    // const openModal = (id:string) => (new Modal(document.querySelector(`#${id}`))).show()
    const setEmptyItem = () => (item.value = emptyItem);
    const kmMask = (v: any) =>
      (attendance.value.km = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."));
    const codConcessionaria: Ref<any> = ref(null);
    const codGrupo:Ref<number | null> = ref(null);
    const cleanModal: Ref<boolean> = ref(false);
    const clicked: Ref<boolean> = ref(false);

    // --------------------------------------------------
    const emptyItem: ItemType = {
      id: null,
      idOrcamento: null,
      codItemLoja: "",
      nome: "",
      valor: "",
      created_at: "",
      updated_at: "",
      status: "",
      codPacote: "",
      valorPeca: "",
      valorMo: "",
      categoria: "",
      codAgregados: "",
      campoCheckList: "",
      desconto: "",
      tipoItem: "",
      qtd: null,
      valorUnitario: null,
      valorOriginal: "",
    }

    const emptyAttendance: attendanceType = {
      id: null,
      placa: "",
      veiculo: "",
      marca: "",
      ano: "",
      km: "",
      fipe: "",
      cliente: "",
      documento: "",
      email: "",
      telefone: "",
      responsavel: "",
      status: "",
      valor: "",
      valorOriginal: null,
      valorDesconto: "",
      valorFinal: "",
      statusIntegracao: "",
      created_at: "",
      observacao: ""
    };
    const statusAttendance: string[] = [
      "Aberto",
      "Enviado",
      "Fechado",
      "Vendido",
      "Cancelado",
      "Andamento",
      "Precificar",
      "Pendente Aprovação",
    ];
    const attendanceId: Ref<number | null> = ref(getUrlParam("id") ? Number(getUrlParam("id")) : null);
    const attendance: Ref<attendanceType> = ref(emptyAttendance);
    const itensArray: Ref<any[]> = ref([]);
    const item: Ref<ItemType> = ref(emptyItem);
    const loadTransmit: Ref<boolean> = ref(false);
    const loadingItemList: Ref<boolean> = ref(false);
    const loadSave: Ref<boolean> = ref(false);
    const store: Ref<any> = ref();
    const vehicleBrandArray: Ref<string[]> = ref([]);
    const ItemModal: Ref<any> = ref();
    const getDownloadAtendimento: Ref<getDownloadAtendimento> = ref({
      idOrcamento: 0,
      idLoja: 0,
      idUsuario: 0,
    });
    const jsonIntegracao: Ref<any> = ref(null)
    // --------------------------------------------------

    // --------------------------------------------------
    async function downloadAtendimento() {
      const { codConcessionaria } = attendance.value as any;

      const userString = localStorage.getItem("user");
      const idUsuario = userString ? JSON.parse(userString).idEncriptado : null;
      getDownloadAtendimento.value = {
        idOrcamento: attendanceId.value ? attendanceId.value : 0,
        idLoja: codConcessionaria.value,
        idUsuario: idUsuario,
      };

      const url = `${process.env.VUE_APP_URL_API}baixaOrcamentos/${getDownloadAtendimento.value.idOrcamento}/${codConcessionaria}/${getDownloadAtendimento.value.idUsuario}`;

      window.open(url, "_blank");
    }

    async function getAttendance() {

      const { data } = await Api.get("getAttendanceById", { id: attendanceId.value });
      attendance.value = data.atendimento;
      store.value = data.loja;
      codGrupo.value = data.loja.codGrupo;
    }

    async function getItens() {
      loadingItemList.value = true
      try{
        const { data } = await Api.get("getAttendanceItens", {
          id: attendanceId.value,
        });
        
        itensArray.value = data.itens;
        attendance.value.valorDesconto = itensArray.value.reduce((total, item) => {
          if (item.desconto && typeof item.desconto === 'string') {
            const desconto = parseFloat(item.desconto.replace(',', '.'));
            total += desconto;
          }
          return total;
        }, 0);
      }catch(error) {
        console.log(error)
      }finally{
        //parar loading
        loadingItemList.value = false
      }
    }

    async function getItem(id: number) {
      if (!id) return;
      loaderStore.open()
      try{
        const { data } = await Api.get("getItemById", { id: id });
        item.value = data.item;
        item.value.valorOriginal = item.value.valorOriginal?.toFixed(2).replace('.', ',');
        enviarEmit()
      }catch(error){
        console.log('error', error)
      }finally{
        loaderStore.close()
      }
    }
    
    async function enviarEmit() {
      await emitter.emit("item-data-attendance-details", {
        item: item.value,
      });
      openModal()
    }

    async function getAllvehicleBrand() {

      const { data } = await Api.get("getAllvehicleBrand");
      vehicleBrandArray.value = data.marcas;

    }

    async function saveAttendance() {

      loadSave.value = true;

      const { data } = await Api.post("saveAttendance", { ...attendance.value });

      loadSave.value = false;

      if(!data.success) return showTimeAlert("Não foi possível salvar o atendimento", "error");

      await getAttendance();

      showTimeAlert("O atendimento foi salvo com sucesso!");

    }

    async function closeModal() {
      ItemModal.value.hide();
      await getAttendance();
    }

    async function transmit() {

      loadTransmit.value = true;

      try {

        const data = await Api.post("transmitAttendance", { id: attendanceId.value });
  
        loadTransmit.value = false;
  
        if (!data.success) return showTimeAlert(data.mensagem, "error");
  
        showTimeAlert(data.mensagem);

      } catch(error) {
        return showTimeAlert('Não foi possível transmitir o atendimento.', "error");
      } finally {
        loadTransmit.value = false; 
        // showTimeAlert('Não foi possível transmitir o atendimento.', "error");
      }
      
    }

    function openModal() {
      ItemModal.value.show();
    }



    const openModalJSON = async (id: string) => {
      const auxElement = document.querySelector(`#${id}`);
      auxModal.value = await new Modal(auxElement);
      auxModal.value.show();
    };

    async function getStatusAtendimentoIntegracao(atd: number | null, loja: number, tipo: string) {
      loaderStore.open()
      try {
        const res = await Api.post('getAtendimentoIntegracao', {tipoIntegracao : tipo, idLoja : loja, idAtendimento : atd, portal: true})
        jsonIntegracao.value = res
        codConcessionaria.value = null
        await openModalJSON("ModalJSONIntegracao");
        jsonIntegracao.value && !jsonIntegracao.value.success  ? showTimeAlert('Atendimento não foi transmitido a integração', "error") : null;
      } catch (error) {
        console.log(error);
      } finally {
        loaderStore.close()
      }
    }

    async function buscaJson(id: number) {
      cleanModal.value = !cleanModal.value;
      codConcessionaria.value = id;
      jsonIntegracao.value = null
      await openModalJSON("ModalJSONIntegracao"); 
      codConcessionaria.value = null
      clicked.value = !clicked.value
    }

    onBeforeMount(async () => {

      await getAttendance();
      await getItens();
      await getAllvehicleBrand();
      ItemModal.value = new Modal(document.querySelector("#EditItemModal"));

    });

    return {
      store,
      attendance,
      attendanceId,
      itensArray,
      history,
      getItens,
      openModal,
      closeModal,
      getItem,
      item,
      setEmptyItem,
      vehicleBrandArray,
      statusAttendance,
      saveAttendance,
      loadSave,
      transmit,
      loadTransmit,
      console,
      openModalJSON,
      codConcessionaria,
      buscaJson,
      cleanModal,
      downloadAtendimento,
      getStatusAtendimentoIntegracao,
      jsonIntegracao,
      auxModal,
      loaderStore,
      codGrupo,
      loadingItemList,
      clicked
    };
  },
});
